import styled from 'styled-components';
import { media } from 'theme/styled-theme';

export const Holder = styled.ul`
  justify-content: space-evenly;
  list-style: none;
  font-family: ${props => props.theme.fontFamilyLato};
  font-size: 0.875rem;
  padding: 0;
  margin: 0;
`;

export const Title = styled.li`
  font-weight: 700;
  margin: 0.5rem 0 0.75rem;
`;

export const Body = styled.li`
  font-weight: 400;
  margin-bottom: 0.75rem;
`;

export const Icon = styled.img`
  margin-right: 1rem;
  width: 1.5rem;
  line-height: 1.25rem;

  ${media.forPhoneOnly`
    margin: 0 0.5rem;
    font-size: 1rem;
  `}
`;

export const Link = styled.a`
  position: relative;
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
  &:focus {
    color: #00b33b;
  }
`;
