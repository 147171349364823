import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Holder, Title } from 'containers/Footer/Footer.styled';
import {
  onErrorHandler,
  ErrorBoundaryFallbackComponent,
  withErrorBoundary
} from 'components/ErrorBoundry/ErrorBoundry';
import { media } from 'theme/styled-theme';
import { sendEvent } from 'utils/gtag';

/* -------------------------------------------------------------------------- */
/*                              styled components                             */
/* -------------------------------------------------------------------------- */

const StyledHolder = styled(Holder)`
  ${media.forPhoneOnly`
    display: flex;
    flex-direction: ${props => props.horizontalOnMobile ? 'row' : 'column'};

    li {
      font-size: ${props => props.horizontalOnMobile ? '0.75rem' : '0.875rem'};
    }
  `}
`;

const Entry = styled.li`
  font-size: 0.875rem;
  margin-bottom: 1rem;
  -webkit-font-smoothing: antialiased;
`;

const LinkSpan = styled.span`
  text-transform: capitalize;
  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const LinkWrap = styled.div`
  ${media.forPhoneOnly`
    ${props => props.hideOnMobile
    ? 'display: none' : 'display: flex'}
  `}
`;

/* -------------------------------------------------------------------------- */
/*                               React Component                              */
/* -------------------------------------------------------------------------- */
const FooterColumn = ({ content, horizontalOnMobile }) => {
  if (!content?.length) {
    return null;
  }

  const createLink = useCallback(({
    to, text, displayTextOnly, hideOnMobile
  }) => (
    <LinkWrap hideOnMobile={hideOnMobile}>
      {displayTextOnly ? text : (
        <Link
          to={to}
          data-testid={`${text}-link`}
          onClick={() => {
            sendEvent({ action: `Click Footer Link ${text}`, category: 'footer' });
          }}
        >
          <LinkSpan>{text}</LinkSpan>
        </Link>
      )}
    </LinkWrap>
  ), []);

  const contentEl = useMemo(() => content.map((item, index) => {
    let key = '';
    if (item.isTitle) {
      // if it's title, safely use text as key
      key = `title-${item.text}-${index}`;
    } else {
      key = item.key
        ? `entry-${item.key}-${index}` // if key present, use key
        : `entry-${item.text}-${index}`; // otherwise use
    }

    return item.isTitle
      ? <Title key={key}>{createLink(item)}</Title>
      : <Entry key={key}>{createLink(item)}</Entry>;
  }), [content]);

  return (
    <StyledHolder
      data-testid="test-footer-column"
      horizontalOnMobile={horizontalOnMobile}
    >
      { contentEl }
    </StyledHolder>
  );
};

FooterColumn.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
  horizontalOnMobile: PropTypes.bool,
};

FooterColumn.defaultProps = {
  content: [],
  horizontalOnMobile: false
};

export default withErrorBoundary(
  FooterColumn,
  ErrorBoundaryFallbackComponent,
  onErrorHandler
);
