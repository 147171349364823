import React, { memo } from 'react';
import PropTypes from 'prop-types';
import lowerCase from 'lodash.lowercase';
import upperFirst from 'lodash.upperfirst';
import styled from 'styled-components';
import { media } from 'theme/styled-theme';
import FooterColumn from './FooterColumn';

const StyledWrap = styled.div`
  ${media.forPhoneOnly`
    flex: 1 0 50%;
  `}
`;

const FooterCountryColumn = memo(props => {
  const { cities = [], country = 'ERROR', extraLinks } = props;

  const countryLinks = [
    {
      text: upperFirst(country),
      to: `/country/${lowerCase(country)}`,
      isTitle: true
    },
    ...cities.map(({ name }) => ({
      text: name,
      to: `/search/${lowerCase(name)}`
    })),
    ...extraLinks.map(({ name, to }) => ({
      text: name,
      to
    }))
  ];

  return <StyledWrap data-testid="test-footer-country"><FooterColumn content={countryLinks} /></StyledWrap>;
});

FooterCountryColumn.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.any),
  country: PropTypes.string,
  extraLinks: PropTypes.arrayOf(PropTypes.any)
};

FooterCountryColumn.defaultProps = {
  extraLinks: [],
  cities: [],
  country: '',
};

export default FooterCountryColumn;
