import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { provideHooks } from 'redial';
import Helmet from 'react-helmet';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import * as dialogsActions from 'redux/modules/dialogs';
import {
  isLoaded as isAuthLoaded,
  load as loadAuth,
  logout
} from 'redux/modules/auth';
import { SnackbarProvider } from 'notistack';
import LoginDialog from 'components/LoginDialog/Loadable';
import citiesReducer, * as citiesActions from 'redux/modules/cities';
import { hotjar } from 'react-hotjar';
import Navigation from 'components/Navigation/Loadable';
import Footer from 'containers/Footer/Footer';
import { sendGtagPageViewEvent } from 'utils/gtag';
// import useWhyDidYouUpdate from 'utils/useWhyDidYouUpdate';
import '../../theme/overwrite.scss';
import '../../theme/nprogress.scss';

/* -------------------------------------------------------------------------- */
/*                              styled components                             */
/* -------------------------------------------------------------------------- */

const EmptyElement = styled.div`
  width: 100%;
`;

const AppContent = styled.div`
  position: relative;
`;

/* -------------------------------------------------------------------------- */
/*                               React Component                              */
/* -------------------------------------------------------------------------- */

const { isLoaded: isCitiesLoaded, load: loadCities } = citiesActions;

const App = props => {
  const {
    cities,
    toggleLoginDialog,
    route,
    history,
    location,
    logout: _logout,
    user,
    mobileDetect
  } = props;
  // eslint-disable-next-line camelcase
  const accessToken = user?.owt?.access_token;
  const { t } = useTranslation('meta');
  const headerMeta = useMemo(() => ({
    meta: [
      {
        name: 'description',
        content: t('META.HOME.DESCRIPTION')
      },
      { charset: 'utf-8' },
      { property: 'fb:app_id', content: '1832315833691859' },
      { property: 'og:site_name', content: 'OWNRIDES' },
      { property: 'og:title', content: t('DEFAULT.OG.TITLE') },
      {
        property: 'og:description',
        content: t('DEFAULT.OG.DESCRIPTION')
      },
      {
        property: 'og:image',
        content: t('DEFAULT.OG.IMAGE'),
      },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.ownrides.com/' },
      { property: 'og:image:width', content: '1200' },
      { property: 'og:image:height', content: '630' }
    ]
  }), [t]);

  useEffect(() => {
    hotjar.initialize(479484, 5); // hjid, hjsv

    // Remove the server-side injected CSS.
    const jssStyles = document.getElementById('jss-server-side');

    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []); // run only once, just like componentDidMount

  useEffect(() => {
    if (typeof window !== 'undefined' && window.Intercom) {
      const userEmail = user?.email ? { email: user?.email } : {};

      window.Intercom('boot', {
        app_id: 'ft0nme10',
        ...userEmail,
      });
    }
  }, [user]); // register when user info change

  useEffect(() => {
    const { action } = history;

    if (action === 'PUSH') window.scrollTo(0, 0);
  }, [location]);

  const handleLoginDialogToggle = useCallback((action = 'login') => {
    toggleLoginDialog(action);
  }, []);

  return (
    <SnackbarProvider maxSnack={3}>
      <div>
        <Helmet {...headerMeta} />
        <Navigation
          toggleLoginDialog={handleLoginDialogToggle}
          accessToken={accessToken}
          logout={_logout}
        />
        <EmptyElement id="sitetoolbar-position" />
        <AppContent>
          {renderRoutes(route.routes)}
        </AppContent>

        <Footer cities={cities} mobileDetect={mobileDetect} />
        <LoginDialog />
      </div>
    </SnackbarProvider>
  );
};

App.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  route: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  toggleLoginDialog: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  cities: PropTypes.arrayOf(PropTypes.any),
  mobileDetect: PropTypes.objectOf(PropTypes.any),
};

App.defaultProps = {
  user: {},
  cities: [],
  mobileDetect: {},
};

export default compose(
  provideHooks({
    inject: ({ store }) => store.inject({ cities: citiesReducer }),
    fetch: async ({ store: { dispatch, getState } }) => {
      const state = getState();
      if (!isAuthLoaded(state)) {
        await dispatch(loadAuth()).catch(() => null);
      }
      if (!isCitiesLoaded(state)) {
        await dispatch(loadCities()).catch(() => null);
      }
    }
  }),
  connect(
    state => ({
      user: state.auth && state.auth.user,
      cities: state.cities && state.cities.cities,
      mobileDetect: state.mobileDetect && state.mobileDetect
    }),
    { logout, ...dialogsActions }
  ),
  withRouter,
)(App);
