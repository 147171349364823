import React from 'react';
import {
  Holder,
  Title,
  Body,
  Icon,
  Link
} from 'containers/Footer/Footer.styled';
import { media } from 'theme/styled-theme';
import styled from 'styled-components';
import config from 'config';
import { sendEvent } from 'utils/gtag';

/* -------------------------------------------------------------------------- */
/*                              styled components                             */
/* -------------------------------------------------------------------------- */

const StyleTitle = styled(Title)`
  ${media.forPhoneOnly`
    display: none;
  `}
`;

const StyleDiv = styled.div`
  flex: 1 0 100%;
  padding-top: 1rem;

  ${media.forPhoneOnly`
    width: auto;
    padding-top: 0;
    flex: inherit;
    margin: 0 0.5rem;
    width: 24px;
    line-height: 20px;
  `}
`;

const StyledBody = styled(Body)`
  flex-wrap: wrap;
  align-items: center;

  ${media.forPhoneOnly`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `}
`;

const StyledHolder = styled(Holder)`
  ${media.forPhoneOnly`
    padding-bottom: 0.5rem;
    display: flex;
  `}
`;

/* -------------------------------------------------------------------------- */
/*                               React Component                              */
/* -------------------------------------------------------------------------- */

const FooterSocialMedia = () => {
  const cdnPrefix = `${config.cdnUrl}/misc`;
  const FbIcon = `${cdnPrefix}/icons/icon-facebook.png`;
  const TwitterIcon = `${cdnPrefix}/icons/icon-twitter.png`;
  const InstagramIcon = `${cdnPrefix}/icons/icon-instagram.png`;
  const Facebook = (
    <Link
      href="https://www.facebook.com/ownrides/"
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        sendEvent({ action: 'Click Facebook', category: 'footer' });
      }}
    >
      <Icon src={FbIcon} alt="Follow us on Facebook" />
    </Link>
  );
  const Twitter = (
    <Link
      href="https://twitter.com/ownrides"
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        sendEvent({ action: 'Click Twitter', category: 'footer' });
      }}
    >
      <Icon src={TwitterIcon} alt="Follow us on Twitter" />
    </Link>
  );
  const Instagram = (
    <Link
      href="https://www.instagram.com/ownrides/"
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        sendEvent({ action: 'Click Instagram', category: 'footer' });
      }}
    >
      <Icon src={InstagramIcon} alt="Follow us on Instagram" />
    </Link>
  );
  const Blog = (
    <Link
      href="https://blog.ownrides.com"
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        sendEvent({ action: 'Click Blog', category: 'footer' });
      }}
    >
      Blog
    </Link>
  );
  return (
    <StyledHolder>
      <StyleTitle>Follow us</StyleTitle>
      <StyledBody>
        {Facebook}
        {Twitter}
        {Instagram}
        <StyleDiv>
          {Blog}
        </StyleDiv>
      </StyledBody>
    </StyledHolder>
  );
};

export default FooterSocialMedia;
