const LOAD = 'cities/LOAD';
const LOAD_SUCCESS = 'cities/LOAD_SUCCESS';
const LOAD_FAIL = 'cities/LOAD_FAIL';

const initialState = () => ({
  loaded: false,
  cities: [],
});

export default function reducer(state = initialState(), action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        cities: action.result,
        error: null
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        cities: null,
        error: typeof action.error === 'string' ? action.error : 'Error'
      };
    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.cities && globalState.cities.loaded;
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get('/cities/load')
  };
}
