import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import styled, { ThemeProvider } from 'styled-components';
import theme from 'theme/styled-theme';

import FooterSocialMedia from 'components/Footer/FooterSocialMedia';
import FooterOwnridesColumn from 'components/Footer/FooterOwnridesColumn';
import FooterCountryColumn from 'components/Footer/FooterCountryColumn';
import FooterSupport from 'components/Footer/FooterSupport';
import { Holder } from './Footer.styled';
import FooterPayment from '../../components/Footer/FooterPayment';

const COUNTRIES = {
  TW: {
    name: 'Taiwan',
    code: 'tw'
  },
  TH: {
    name: 'Thailand',
    code: 'th'
  }
};

/* -------------------------------------------------------------------------- */
/*                              styled components                             */
/* -------------------------------------------------------------------------- */

const Background = styled.div`
  background: #3f4c61;
  padding-top: 24px;
  color: #ffffff;
`;

const LineDivider = styled(Background)`
  border-top: 1px solid #ffffff;
  text-align: center;
  margin: 0 auto;
  padding: 1rem 0 1.5rem;
`;

const BottomText = styled.span`
  display: inline-block;
  font-size: 14px;
  color: white;
  padding: 0.1rem;
`;

const Layout = styled.nav`
  justify-content: space-between;
  list-style-type: none;
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 1rem;
`;

const StyledHolder = styled(Holder)`
  display: flex;
  align-items: flex-start;
  justify-content: end;
`;

/* -------------------------------------------------------------------------- */
/*                              React component                               */
/* -------------------------------------------------------------------------- */

const Footer = memo(({ cities, mobileDetect }) => {
  // const { t } = useTranslation('new-version');
  const { isTablet, isMobile } = mobileDetect;
  const isMobileUser = isMobile && !isTablet;
  const twCities = useMemo(
    () => cities?.length > 0
      ? cities.filter(city => city.country.code === COUNTRIES.TW.code)
      : [],
    []
  );

  const thCities = useMemo(
    () => cities?.length > 0
      ? cities.filter(city => city.country.code === COUNTRIES.TH.code)
      : [],
    []
  );

  const extraTaiwanLinks = [{ name: 'Taiwan B&Bs', to: '/taiwan/places' }];
  // const Copyright = <BottomText>{t('COMMON.FOOTER.COPYRIGHT')}</BottomText>;
  // temporary change (not using locale) only
  const Copyright = <BottomText>© 2016-2022 OWNRIDES Pte. Ltd.</BottomText>;

  return (
    <ThemeProvider theme={theme}>
      <Background key="ownrides-footer">
        {isMobileUser ? (
          <>
            <div className="container">
              <FooterSocialMedia />
              <StyledHolder>
                <FooterCountryColumn
                  country={COUNTRIES.TW.name}
                  cities={twCities}
                  extraLinks={extraTaiwanLinks}
                />
                <FooterCountryColumn
                  country={COUNTRIES.TH.name}
                  cities={thCities}
                />
              </StyledHolder>
              <Layout>
                <Holder>
                  <FooterSupport />
                  <FooterPayment />
                </Holder>
              </Layout>
            </div>
            <LineDivider>
              <FooterOwnridesColumn />
              {Copyright}
            </LineDivider>
          </>
        ) : (
          <>
            <Layout className="container">
              <FooterOwnridesColumn />
              <FooterCountryColumn
                country={COUNTRIES.TW.name}
                cities={twCities}
                extraLinks={extraTaiwanLinks}
              />
              <Holder>
                <FooterCountryColumn
                  country={COUNTRIES.TH.name}
                  cities={thCities}
                />
                <FooterSocialMedia />
              </Holder>
              <Holder>
                <FooterSupport />
                <FooterPayment />
              </Holder>
            </Layout>
            <LineDivider>{Copyright}</LineDivider>
          </>
        )}
      </Background>
    </ThemeProvider>
  );
});

Footer.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.any).isRequired,
  mobileDetect: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Footer;
