import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'react-loading-animation';

const LoginDialogLoadable = Loadable({
  loader: () => import('./LoginDialog')
    .then(module => module.default)
    .catch(e => { console.log(e); if (window) window.location.reload(); }),
  loading: () => <div key="LoginDialogLoadableLoading"><Loading /></div>
});

export default LoginDialogLoadable;
