import React from 'react';
import styled from 'styled-components';
import {
  Holder, Title, Body
} from 'containers/Footer/Footer.styled';
import payment from '../../assets/payment-method.svg';

const Image = styled.span`
  border: 0;
  display: inline-block;
  background-image: url('${props => props.payment}');
  background-size: cover;
  height: 32px;
  margin: 0px 16px 8px 0;
  overflow: hidden;
  text-indent: -500px;
  width: 48px;
`;

const FooterPayment = () => {
  const MastercardImage = styled(Image)`
    background-position: -215px 0;
  `;
  const AmericanExpressImage = styled(Image)`
    background-position: -107px 0;
  `;
  const VisaImage = styled(Image)`
    background-position: 0px 0;
  `;
  return (
    <Holder>
      <Title>Payment Channel</Title>
      <Body>
        <VisaImage payment={payment} />
        <MastercardImage payment={payment} />
        <AmericanExpressImage payment={payment} />
      </Body>
    </Holder>
  );
};

export default FooterPayment;
