import React from 'react';
import { sendEvent } from 'utils/gtag';
import { Link } from 'react-router-dom';
import FooterColumn from './FooterColumn';

const FooterOwnridesColumn = () => {
  const input = [
    {
      text: 'OWNRIDES',
      displayTextOnly: true,
      isTitle: true,
      hideOnMobile: true
    },
    {
      text: 'About Us',
      to: '/about'
    },
    {
      text: 'Service policy',
      to: '/policy'
    },
    {
      text: 'Privacy policy',
      to: '/privacy'
    },
    {
      text: 'Terms of service',
      to: '/terms'
    },
    {
      text: (
        <Link
          to="/help"
          target="_blank"
          onClick={() => {
            sendEvent({ action: 'Click Help', category: 'footer' });
          }}
        >
          Help
        </Link>
      ),
      key: 'help',
      displayTextOnly: true,
      hideOnMobile: true,
    }
  ];

  return <FooterColumn content={input} horizontalOnMobile />;
};

export default FooterOwnridesColumn;
