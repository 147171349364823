import React from 'react';
import styled from 'styled-components';
import {
  Holder, Title, Body, Link
} from 'containers/Footer/Footer.styled';
import FbMessage from 'assets/ic_fbmessenger_white.svg';
import { sendEvent } from '../../utils/gtag';

/* -------------------------------------------------------------------------- */
/*                              styled components                             */
/* -------------------------------------------------------------------------- */

const FbLink = styled(Link)`
  color: #1e90ff;
  background-color: white;
  padding: 0.25rem 0.75rem 0.25rem 0.5rem;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 0.75rem;

  img {
    width: 24px;
    height: 24px;
    margin-right: 0.25rem;
  }

  &:visited {
    color: #1e90ff;
  }
  &:hover {
    color: #1e90ff;
  }
`;

/* -------------------------------------------------------------------------- */
/*                               React Component                              */
/* -------------------------------------------------------------------------- */

const FooterSupport = () => (
  <Holder>
    <Title>Support</Title>
    <Body>
      <Link
        href="mailto:support@ownrides.com"
        onClick={() => {
          sendEvent({ action: 'Click Email Support', category: 'footer' });
        }}
      >
        Mail: support@ownrides.com
      </Link>
    </Body>
    <Body>
      <FbLink
        href="https://www.messenger.com/t/680688862090703"
        target="_blank"
        rel="noopener noreferrer nofollow"
        onClick={() => {
          sendEvent({ action: 'Click FB Message Us', category: 'footer' });
        }}
      >
        <img src={FbMessage} alt="Need any help? Message Us!" />
        <span>Message Us</span>
      </FbLink>
    </Body>
  </Holder>
);

export default FooterSupport;
